﻿@import '../template.scss';

.tim-select {
    @include control-template;
    cursor: pointer;

    &:hover {
        @include control-template-hover;
    }

    option {
        background: #fff;
        color: black;
    }

    optgroup {
        font-size: 1em;
    }
}

.tim-select-form {
    width: 100%;
}
