﻿//login icon kleuren
$login-icons-background-color: #56a5a6;
$login-icons-text-color: #fff;

// de kleuren van de active vraag en inspiratie citaat
$active-div-background-color: #56a5a6;
$active-div-text-color: #333;

//navbar kleuren
$navbar-background-color: #56a5a6;
$navbar-text-color: #fff;
$navbar-border-color: #75c3c4;

$navbar-background-color-hover: #70d7d8;
$navbar-text-color-hover: #fff;
$navbar-border-color-hover: #75c3c4;

//tabs kleuren
$tabs-background-color: #56a5a6;
$tabs-text-color: #fff;

$tabs-background-color-hover: #70d7d8;
$tabs-text-color-hover: #fff;

$tabs-active-background-color-hover: #70d7d8;
$tabs-active-text-color-hover: #fff;

//form input kleuren
$form-background-color: #56a5a6;
$form-border-color: #75c3c4;
$form-text-color: #fff;

$form-background-color-hover: #70d7d8;
$form-border-color-hover: #75c3c4;
$form-text-color-hover: #fff;

//de algemene kleuren
$body-background-color: #ecfefb;
$body-text-color: #56a5a6;

$body-background-color-hover: #d9fdf6;
$body-text-color-hover: #333;

//modal kleuren
$modal-text-color: #56a5a6;
$modal-background-color: #ecfefb;

//lijst kleuren
$list-container-text-color-hover: #333;

$list-container-background-color-hover: #56a5a6;

//kleuren voor de status teksten

$hoog: red;

$middel: orange;

$laag: purple;

$open: purple;

$in-behandeling: orange;

$gesloten: red;

$nieuw: purple;

$termijndag: orange;

$geweigerd: red;

$betaald: purple;

$gewijzigd: orange;

$besteld: purple;

$aangemaakt: purple;