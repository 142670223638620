﻿@import '../dashboard/_styles/colors.scss';

.modal-backdrop {
    opacity: 0.40;
}

.modal-dialog {
    z-index: 1080;
}

.modal-background {
    background-color: $modal-background-color;
    color: $modal-text-color;
}

.modal-text-error {
    color: red;
}

.modal-text-success {
    color: green;
}