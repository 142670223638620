﻿@import 'form-components/button.scss';
@import 'form-components/checkbox.scss';
@import 'form-components/select.scss' ;

fieldset[disabled] {
    cursor: default;
    pointer-events: none;
}

.table {
    margin-bottom: 0;
}

.screenheight {
    min-height: 740px;
}